export const SET_DARK_MODE = 'theme/SET_DARK_MODE';
export const TOGGLE_DARK_MODE = 'theme/TOGGLE_DARK_MODE';

export const setDarkMode = (isDarkMode) => ({ type: SET_DARK_MODE, isDarkMode });
export const toggleDarkMode = () => ({ type: TOGGLE_DARK_MODE });

export const initialState = {
  isDarkMode: false,
};

export const themeReducer = (themeState, action) => {
  switch (action.type) {
    case SET_DARK_MODE:
      return { ...themeState, isDarkMode: action.isDarkMode };
    case TOGGLE_DARK_MODE:
      return { ...themeState, isDarkMode: !themeState.isDarkMode };
    default:
      return themeState;
  }
};

import React, { createContext, useEffect, useReducer } from 'react';
import { initialState, setDarkMode, themeReducer } from '@reducers/themeReducer';
import { getValueFromLocalStorage, setValueToLocalStorage } from '@utils/localStorage';
import { isMatchingMediaQuery } from '@utils/matchMedia';

export const ThemeContext = createContext({
  themeState: {
    isDarkMode: false,
  },
  themeDispatch: () => {},
});

export const ThemeProvider = ({ children }) => {
  const [themeState, themeDispatch] = useReducer(themeReducer, initialState);

  const { isDarkMode } = themeState;

  useEffect(() => {
    const localStorageTheme = getValueFromLocalStorage('isDarkMode');
    if (localStorageTheme === null) {
      const userPreferTheme = isMatchingMediaQuery('(prefers-color-scheme: dark)');
      themeDispatch(setDarkMode(userPreferTheme));
    } else {
      themeDispatch(setDarkMode(localStorageTheme));
    }
  }, []);

  useEffect(() => {
    setValueToLocalStorage('isDarkMode', isDarkMode);
    document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  return <ThemeContext.Provider value={{ themeState, themeDispatch }}>{children}</ThemeContext.Provider>;
};
